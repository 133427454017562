// import axios from "axios";

const version = "v1";
export function fetchPointSettings(merchantId) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings`,
  };
  return window.qcsso.request(config);
}
export function fetchStores(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/stores`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchPointPeriods(merchantId, pointId) {
  // var config = {
  //   method: "get",
  //   url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${pointId}/periods`,
  // };
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/periods`,
  };
  if (pointId) {
    config.url = `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${pointId}/periods`;
  }
  return window.qcsso.request(config);
}

export function fetchAnnualBudgets(merchantId, pointId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${pointId}/annual-budgets`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchDepartmentBudgets(merchantId, pointId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${pointId}/department-budgets`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchCampaignBudgets(merchantId, pointId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${pointId}/campaign-budgets`,
    params: querystring,
  };
  return window.qcsso.request(config);
}
export function fetchRoles(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/iam/roles`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchUsers(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/users`,
    params: querystring,
  };
  return window.qcsso.request(config);
}
export function fetchOrganizations(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/organizations`,
    params: querystring,
  };
  return window.qcsso.request(config);
}
export function fetchDepartments(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments`,
    params: querystring,
  };
  return window.qcsso.request(config);
}
export function fetchStoreCategories(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/store-categories`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchPointStats(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-stats`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchPointTopN(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-topN`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchAnnualBudgetDetail(merchantId, budgetId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/annual-budgets/${budgetId}/ledgers`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchDepartmentBudgetDetail(merchantId, budgetId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/department-budgets/${budgetId}/ledgers`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchCampaignBudgetDetail(merchantId, budgetId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/campaign-budgets/${budgetId}/ledgers`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchWithdrawals(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/withdrawals`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchBankList(merchantId) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banks`,
  };
  return window.qcsso.request(config);
}
export function fetchBankBranchList(merchantId, bankId) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banks/${bankId}/branches`,
  };
  return window.qcsso.request(config);
}

export function exportApprovedWithdrawalsList(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/withdrawals/export`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchCampaigns(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/campaigns`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchCampaignMissions(merchantId, campaignId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/campaigns/${campaignId}/missions`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchCampaignRewards(merchantId, campaignId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/campaigns/${campaignId}/rewards`,
    params: querystring,
  };
  return window.qcsso.request(config);
}
export function fetchBanners(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banners`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchArticles(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/articles`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchArticleCategories(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-categories`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchSettlementDepartment(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/settlement-department`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchArticleSubCategories(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-subcategories`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function exportCampaignEntryCode(
  merchantId,
  campaignId,
  missionId,
  querystring
) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/campaigns/${campaignId}/missions/${missionId}/entry-codes`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchMembers(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/members`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchMemberCards(merchantId, memberId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/members/${memberId}/cards`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

// 取得會員收支明細表
export function getMemberWalletLedgers(
  merchantId,
  memberId,
  pointId,
  querystring
) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/members/${memberId}/points/${pointId}/ledgers`,
    params: querystring,
  };

  return window.qcsso.request(config);
}
export function fetchStoreStats(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/store-stats`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function exportBankRemittance(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/withdrawals/export-remittance`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchDonateInvoiceCampaign(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/donate-invoices/campaign`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchDonateCandidateCampaigns(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/donate-invoices/campaigns`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchDonateInvoiceStats(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/donate-invoices/stats`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchPrememberList(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/pre-members`,
    params: querystring,
  };
  return window.qcsso.request(config);
}

export function fetchQueryPresets(querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/query-presets`,
    params: querystring,
  };
  return window.qcsso.request(config);
}
